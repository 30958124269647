@import '@/styles/variables.scss';

.Form {
  max-width: 600px;
  margin: 0 auto;
}

.Message {
  text-align: center;
}
